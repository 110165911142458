import React from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import format from "date-fns/format";
import sub from "date-fns/sub";
import MetaTags from "../components/MetaTags";
import Container from "../components/Container";
import H1 from "../components/H1";
import PostWarning from "../components/PostWarning";
import ClapButton from "../components/ClapButton";
import styled from "styled-components";

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      fileAbsolutePath
      frontmatter {
        title
        subtitle
        slug
        date
      }
      body
    }
  }
`;

const PostWrapper = styled.article`
  max-width: 1440px;
  margin: 0 auto;

  h2,
  h3 {
    font-family: "Muli", sans-serif;
    letter-spacing: -0.05rem;

    &:not(:first-of-type) {
      margin-top: 3rem;
    }
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 300;
  }

  h1 + h2 {
    font-size: 1.8rem;
    font-weight: 300;
    color: #636363;
  }
`;

const MetaWrapper = styled.div`
  margin: 0.75rem 0 3rem;
  font-size: 1.2rem;
  color: gray;
`;

function PostTemplate({ data: { mdx: post }, scope }) {
  const FiveYearsAgo = sub(new Date(), { years: 5 });
  return (
    <PostWrapper>
      <MetaTags title={post.frontmatter.title} />
      <Container>
        <H1>{post.frontmatter.title}</H1>
        <h2>{post.frontmatter.subtitle}</h2>
        <MetaWrapper>
          <time dateTime={post.frontmatter.date}>
            {format(new Date(post.frontmatter.date), "do MMMM yyyy")}
          </time>
        </MetaWrapper>
        {post.frontmatter.date < format(FiveYearsAgo, "yyyy-mm-dd") ? (
          <PostWarning />
        ) : null}
        <MDXRenderer
          scope={{
            ...scope,
          }}
        >
          {post.body}
        </MDXRenderer>
        <ClapButton page={`${post.id}`} />
        <Link to="/posts">Back to posts</Link>
      </Container>
    </PostWrapper>
  );
}

export default PostTemplate;
