import React from "react";
import styled from "styled-components";

const LoadingWrapper = styled.div`
  --loading-bar-width: 4px;

  @keyframes loading {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1, 2.2);
    }
    40% {
      transform: scale(1);
    }
  }

  display: inline-flex;

  > div {
    margin-top: 0;
    > * {
      margin-top: 0;
    }

    > div {
      display: inline-block;
      width: var(--loading-bar-width);
      height: 18px;
      margin-right: var(--loading-bar-width);
      animation: loading 1s ease-in-out infinite;
    }

    > div:nth-child(1) {
      background-color: var(--bright-green);
      animation-delay: 0;
    }
    > div:nth-child(2) {
      background-color: var(--bright-green);
      animation-delay: 0.09s;
    }
    > div:nth-child(3) {
      background-color: var(--bright-green);
      animation-delay: 0.18s;
    }
    > div:nth-child(4) {
      background-color: var(--bright-green);
      animation-delay: 0.27s;
    }
  }

  .loading-text {
    display: flex;
    align-items: center;
    margin: 0 0 0 calc(var(--spacing-unit) / 2);
    color: var(--fill-green);

    > span {
      font-size: 1rem;
      line-height: 1;
    }
  }
`;

function Loading(props) {
  return (
    <LoadingWrapper>
      <div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="loading-text">
        <span>{props.text}</span>
      </div>
    </LoadingWrapper>
  );
}

export default Loading;
