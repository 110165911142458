import React from "react";
import styled from "styled-components";

const PostWarningWrapper = styled.div`
  background: #fff2f2;
  color: #ff4242;
  margin-bottom: 3rem;
  padding: 1.5rem 2rem 2rem;
  border: 1px solid #ff4242;
  font-weight: bold;
`;

const PostWarning = () => (
  <PostWarningWrapper>
    <p>
      Woah there! This post is pretty old and will likely contain dated
      information and opinions that have changed.
    </p>
  </PostWarningWrapper>
);

export default PostWarning;
