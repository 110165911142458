import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import Loading from "./Loading";
import styled from "styled-components";

const trackingId = "UA-171724862-1";
const clapApiUrl = "https://clap-button.herokuapp.com";
const clapStartDate = "2020-09-04";

const ClapButtonWrapper = styled.div`
  @keyframes Pulse {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }

  margin-top: 5rem;

  button {
    position: relative;
    appearance: none;
    display: inline-block;
    height: 3.75rem;
    width: 3.75rem;
    margin-right: var(--spacing-unit);
    border: none;
    border-width: 1px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 3px 2px var(--bright-green);
    font-size: 1.8rem;
    cursor: pointer;
    transition: box-shadow 0.2s ease;
    outline: 0;

    > span {
      margin: 0;
      transform: scale(1);
    }
    &:hover {
      box-shadow: 0 0 8px 2px var(--bright-green);
    }
  }

  .clap-count {
    display: inline-block;
    font-size: 1rem;
  }

  .button-pulse {
    animation: 0.5s ease BubbleIn;
  }
`;

const ClapBubble = styled.span`
  @keyframes BubbleIn {
    0% {
      opacity: 0;
      transform: scale(0.7);
    }

    85% {
      opacity: 1;
      transform: scale(1.05);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes BubbleOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: scale(1.3) translateY(-2rem);
    }
  }

  display: inline-flex;
  justify-content: center;
  align-self: center;
  position: absolute;
  top: -3rem;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: calc(var(--starSize) * 0.5);
  pointer-events: none;
  z-index: 10;

  .clap-bubble {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    background-color: var(--text-green);
    color: white;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    opacity: 0;
  }

  .clap-bubble--is-clapping {
    animation: 0.5s ease forwards BubbleIn;
  }

  .clap-bubble--anim-out {
    animation: 0.5s ease-in normal BubbleOut;
  }
`;

function ClapButton(props) {
  const [gAClapCount, setGaClapCount] = useState(0);
  const [localClapCount, setLocalClapCount] = useState(0);
  const [isClapping, setIsClapping] = useState("clap-bubble");
  const [isLoadingClap, setIsLoadingClap] = useState(true);
  const [clapError, setClapError] = useState(null);

  const eventStringBuilder = id => {
    const formattedId =
      process.env.NODE_ENV === "production"
        ? `${encodeURI(id.toUpperCase())}`
        : `${encodeURI(`DEV-${id.toUpperCase()}`)}`;
    return formattedId;
  };

  const clapButtonHandler = page => {
    ReactGA.initialize(trackingId);

    ReactGA.event({
      category: "Post",
      action: eventStringBuilder(page),
    });

    setLocalClapCount(localClapCount + 1);
  };

  useEffect(() => {
    if (localClapCount > 0) {
      setIsClapping("clap-bubble clap-bubble--anim clap-bubble--is-clapping");
      const timer = setTimeout(() => {
        setIsClapping("clap-bubble clap-bubble--anim-out");
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [localClapCount]);

  useEffect(() => {
    fetch(
      `${clapApiUrl}/claps?post=${eventStringBuilder(
        props.page
      )}&startDate=${clapStartDate}`
    )
      .then(response => response.json())
      .then(res => {
        setGaClapCount(res.data.value);
        setIsLoadingClap(false);
      })
      .catch(error => {
        setIsLoadingClap(false);
        setClapError(`Clap count could not be fetched right now :(`);
        console.error(error);
      });
  }, [props.page]);

  return (
    <ClapButtonWrapper>
      <button type="button" onClick={() => clapButtonHandler(props.page)}>
        <span className="visually-hidden">Clap this post.</span>
        <span role="img" aria-label="Clap">
          👏
        </span>
        <ClapBubble>
          <span className={isClapping}>
            <span>+{localClapCount}</span>
          </span>
        </ClapBubble>
      </button>

      {!isLoadingClap && !clapError ? (
        <div className="clap-count">
          <p>
            <span className="visually-hidden">This post has recieved </span>
            {gAClapCount + localClapCount}{" "}
            {gAClapCount + localClapCount !== 1 ? "claps" : "clap"}
          </p>
        </div>
      ) : !clapError ? (
        <Loading text="Loading clap count" />
      ) : (
        <p>{clapError}</p>
      )}
    </ClapButtonWrapper>
  );
}

export default ClapButton;
